import React from 'react'
import './AboutMe.css'
import Undi from '../../Images/undi.png'
import JS from '../../Images/skills/JS logo.png'
import CSS from '../../Images/skills/css.png'
import HTML from '../../Images/skills/html.png'
import ReactLogo from '../../Images/skills/reactjs.png'
import Node from '../../Images/skills/nodejs.png'
import Express from '../../Images/skills/express.png'
import MongoDB from '../../Images/skills/mongo.png'
import MySql from '../../Images/skills/mysql.png'
import Postman from '../../Images/skills/postman.png'
import Git from '../../Images/skills/git.png'

function AboutMe() {
    return (
        <>
            <div className="aboutContainer">
                {/* <video autoPlay muted loop id="video-bg">
                    <source src={bgVideo} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video> */}
                <div className="aboutBox2">
                    <h1>About Me</h1>
                    <img src={Undi} alt="Himali" />
                    <p>I'm Shekhar Pandge, a web developer who loves building websites that look great and work smoothly on any device. My main focus is on front-end development, where I use HTML, CSS, JavaScript, and React to create websites that are easy to use and visually appealing.</p>
                    <p>I enjoy taking ideas and turning them into real, working sites that provide a great experience for users.</p>
                    <div className="skills">
                        <div className="box"><img src={HTML} alt="" /></div>
                        <div className="box"><img src={JS} alt="" /></div>
                        <div className="box"><img src={CSS} alt="" /></div>
                        <div className="box"><img src={ReactLogo} alt="" /></div>
                        <div className="box"><img src={Node} alt="" /></div>
                        <div className="box"><img src={Express} alt="" /></div>
                        <div className="box"><img src={MongoDB} alt="" /></div>
                        <div className="box"><img src={MySql} alt="" /></div>
                        <div className="box"><img src={Postman} alt="" /></div>
                        <div className="box"><img src={Git} alt="" /></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutMe;
