import React, { useState } from 'react'
import './Header.css'
import Logo from '../../Images/shekharLogo.png'
import Undi from '../../Images/undi.png'
import { Link,NavLink } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className={isMenuOpen ? "mobileMenu open" : "close"}>
      <img src={Logo} alt="Himali" />
        <li><NavLink to="/" className="navlink" onClick={toggleMenu}>Home</NavLink></li>
        <li><NavLink to="/aboutme" className="navlink" onClick={toggleMenu}>About Me</NavLink></li>
        <li><NavLink to="/projects" className="navlink" onClick={toggleMenu}>My Projects</NavLink></li>
        <li><NavLink to="/contactme" className="navlink" onClick={toggleMenu}>Contact Me</NavLink></li>
        <button><Link to="https://wa.link/ne1iz2" >WhatsApp Me</Link></button>
        <button><Link to="tel:+91 98332 75472" >Call Me</Link></button>
      </div>
      <div className="logo">
        <img src={Logo} alt="Himali" />
      </div>
      <div className="navbar">
        <img src={Undi} alt="Himali" />
        <ul>
          <li><NavLink to="/" className="navlink">Home</NavLink></li>
          <li><NavLink to="/aboutme" className="navlink">About Me</NavLink></li>
          <li><NavLink to="/projects" className="navlink">My Projects</NavLink></li>
          <li><NavLink to="/contactme" className="navlink">Contact Me</NavLink></li>
        </ul>
        <img src={Undi} alt="Himali" />
      </div>
      <div className="navBtn">
        <button><NavLink to="/resume" className="navlink">My Resume</NavLink></button>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <i className={isMenuOpen ? "fa-solid fa-times" : "fa-solid fa-bars"}></i>
      </div>
    </header>
  );
};

export default Header;
