import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer/Footer'
import ScrollToTop from './ScrollToTop/Scroll'
import Preloader from './Preloader/Preloader'

function Layout() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show the preloader for 2 seconds whenever the URL changes
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [location]);
  return (
    <>
    {loading && <Preloader />} {/* Show preloader only when loading */}
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer/>
    </>
  )
}

export default Layout
