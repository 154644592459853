import React, { useState }from 'react'
import { useNavigate } from 'react-router-dom';
import './Resume.css'
import Undi from '../../Images/undi.png'

function ContactMe() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email)
    window.open(process.env.PUBLIC_URL + '/Shekhar-pandge-resume.pdf', '_blank');
  };
    return (
      <>
        <div className="resumeContainer">
          <div className="heading">
            <h1>View Resume</h1>
            <img src={Undi} alt="Himali" />
          </div>
          <div className="resumeBox">
            <form onSubmit={handleSubmit} >
              <input type="email"
                id="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)} placeholder='Your Email' />
              <button type='submit'>Submit</button>
            </form>
          </div>
        </div>
      </>
    )
  }

  export default ContactMe
