import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './Components/Layout.jsx';
import Home from './Components/Home/Home.jsx';
import AboutMe from './Components/AboutMe/AboutMe.jsx'
import ContactMe from './Components/ContactMe/ContactMe.jsx';
import Projects from './Components/Projects/Projects.jsx';
import Resume from './Components/Resume/Resume.jsx'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "/aboutme",
        element: <AboutMe />
      },
      {
        path: "/projects",
        element: <Projects />
        
      },
      {
        path: "/contactme",
        element: <ContactMe />
      },
      {
        path: "/resume",
        element: <Resume />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

