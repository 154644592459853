import React from 'react'
import './ContactMe.css'
import Undi from '../../Images/undi.png'

function ContactMe() {
  return (
    <>
    <div className="contactContainer">
                {/* <video autoPlay muted loop id="video-bg">
                    <source src={bgVideo} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video> */}
                 <div className="heading">
                    <h1>Contact Me</h1>
                    <img src={Undi} alt="Himali" />
                    </div>
                <div className="contactBox2">
                    <form action="">
                        <input type="text" placeholder='Your Name'/>
                        <input type="text" placeholder='Your Company Name'/>
                        <input type="tel" placeholder='Your Contact Number'/>
                        <input type="email" placeholder='Your Email'/>
                        <textarea  cols="30" rows="5" placeholder='Message For Me'></textarea>
                        <button>Submit</button>
                    </form>
                </div>
            </div>
    </>
  )
}

export default ContactMe
