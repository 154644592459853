import React from 'react'
import './Home.css'
import Undi from '../../Images/undi.png'
import UndiV from '../../Images/undiV.png'
import { Link, NavLink } from "react-router-dom";


function Home() {
  return (
    <>
      <main className='mainContainer'>
        {/* <video autoPlay muted loop id="video-bg">
        <source src={bgVideo} type="video/mp4"></source>
        Your browser does not support the video tag.
      </video> */}
        {/* <img src={Photo} alt="" /> */}

        <div className="homeLeft">
          <Link to="https://www.facebook.com/shekhar.pandge.9/" target='himu' className="navlink"><i class="fa-brands fa-facebook"></i></Link>
          <Link to="https://www.instagram.com/shekhar_s91/" target='himu' className="navlink"><i class="fa-brands fa-instagram"></i></Link>    
          <img src={UndiV} alt="" />
        </div>

        <div className="homeCenter">
          <h1>Hello, I'm Shekhar Pandge,</h1>
          <img src={Undi} alt="" />
          <h2>Web Developer based in Mumbai.</h2>
          <p>I create clean, user-friendly, and functional websites. With expertise in HTML, CSS, JavaScript, React, and WordPress, I enjoy transforming complex ideas into engaging online experiences.</p>
          <div className="buttons">
            <button><NavLink to="/projects" className="navlink">My Projects</NavLink></button>
            <button><NavLink to="/resume" className="navlink">My Resume</NavLink></button>
          </div>
        </div>
        <div className="homeRight">
        <img src={UndiV} alt="" />
        <Link to="www.linkedin.com/in/shekhar-pandge-7136b0203" target='himu' className="navlink"><i class="fa-brands fa-linkedin"></i></Link>
        <Link to="mailto:shekharpandge2002@gmail.com" target='himu' className="navlink"><i class="fa-solid fa-envelope"></i></Link>
        </div>


      </main>
    </>
  )
}

export default Home
